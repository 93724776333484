<template>
  <div class="body">
    <div class="main-wrapper">
      <div class="main-content">
        <div class="container container-welcome text-center">
          <img class="mx-auto mb-5" src="@/assets/kamsia-edited.png" alt="" />
          <h1 class="text-kamsia font-weight-bold">Kaki Lima Indonesia</h1>
          <p class="text-kamsia mb-4 mx-3">
            Selamat datang di Kaki Lima Indonesia! Temukan kelezatan makanan
            khas kaki lima dari berbagai penjuru kota dalam genggaman Anda.
          </p>
          <a
            href="/resto"
            id="btn-welcome"
            class="btn btn-welcome btn-lg btn-rounded w-100"
          >
            Mulai Sekarang !
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomeComponent",
  props: {
    //
  },
  mounted() {
    window.checkFunctionExists = () => {
      return typeof this.goBackFlutter === "function";
    };

    window.goBackFlutter = () => {
      this.goBackFlutter();
    };

    const isLocationSet = localStorage.getItem("latitude", null) != null;

    if (!isLocationSet) {
      navigator.geolocation.getCurrentPosition((position) => {
        localStorage.setItem("latitude", position.latitude);
        localStorage.setItem("longitude", position.longitude);
      });
    }

    if (
      localStorage.getItem("user") !== null &&
      localStorage.getItem("user") !== ""
    ) {
      this.$router.push("/resto");
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
* {
  font-family: "Nunito", sans-serif;
}

h1 {
  font-size: 2rem;
}

p {
  font-size: 1rem;
  font-weight: 500;
}

.main-wrapper {
  background-color: #fbb143;
  height: 100vh;
  /* Set the height to 100% of the viewport height */
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-kamsia {
  color: #604226;
}

img {
  max-height: 30vh;
}

.main-content {
  margin-top: -30px;
}
</style>
